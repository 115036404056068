<template>
	<div class="AgreeMent">
		<div class="content">
			<div class="item">
				<p>欢迎加入剑盟！</p>
				<p>剑盟，是由 AVEVA | 剑维软件 专注打造的工业领域精锐社区，搭建技术交流与资源共享的平台，更有丰富的活动，驱动技能提升与个人的成长！</p>
				<p>请您仔细阅读以下条款，如果您对本协议的任何条款有异议，您有权选择不进入。当您注册成功，无论是进入剑盟，还是在剑盟上发布任何内容，意味着您（即「用户」）完全接受本协议项下的全部条款。</p>
			</div>
			<div class="item">
				<p class="title">一、总览</p>
				<p>
					1.1 剑盟严禁任何违背中华人民共和国法律法规之言论，一经发现，将删除帖子、取消会员资格。同时，我们将积极配合和协助有关执法机关的取证调查。
				</p>
				<p>
					1.2 用户注册成功后，剑盟将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
				</p>
				<p>
					1.3 剑盟不欢迎以下言论，管理员或版主有权删除此类帖子乃至屏蔽注销发布者之注册名和IP地址。
				</p>
				<p class="indent">
					1.3.1 发表内容与剑盟主题不符；
				</p>
				<p class="indent">
					1.3.2 转贴未注明，或原版权所有者拒绝转载的；
				</p>
				<p class="indent">
					1.3.3 讨论盗版软件、注册码、软件破解的；
				</p>
				<p class="indent">
					1.3.4 无实际内容，纯属灌水的；
				</p>
				<p class="indent">
					1.3.5 利用剑盟对他人（包括网友）进行人身攻击的；
				</p>
				<p class="indent">
					1.3.6 与政治、军事、国家机密相关言论；
				</p>
				<p class="indent">
					1.3.7 其他对剑盟或者其他网友有所损害的发言。
				</p>
				<p>
					1.4 请勿使用以下注册名，一经查实，剑盟有权屏蔽或删除：
				</p>
				<p class="indent">
					1.4.1 领导人和其他名人的真实姓名、字号；
				</p>
				<p class="indent">
					1.4.2 国家机构或其他机构的名称，包括剑维软件及其他域名；
				</p>
				<p class="indent">
					1.4.3 不文明、不健康的名字；
				</p>
				<p class="indent">
					1.4.4 易产生歧义、引起他人误解之名字；
				</p>
				<p class="indent">
					1.4.5 手机号码或QQ号码之类联系方式的名字；
				</p>
				<p class="indent">
					1.4.6 其他我们认为不合适的名字。
				</p>
				<p>
					1.5 注册时请提供有效的邮箱或手机号码。我们不会主动公开您的邮箱及手机号。
				</p>
				<p>
					1.6 注册密码由成员自己保存，万一遗忘，可通过手机验证码登录。如果您提供的邮箱、手机号码无效，或者密码提醒功能无法向您提供的邮箱、手机发送您的个人信息，请联系客服，请勿重复注册。
				</p>
				<p>
					1.7
					所有在剑盟发表的原创帖子，视为作者向剑盟的投稿。版权归剑盟及作者共有，剑盟有权在不通知作者的情况下将其编辑出版。剑盟保留对所有主题的删除、修改、移动的权利。未经剑盟同意，严禁任何形式的转载、发表、出版。
				</p>
			</div>
			<div class="item">
				<p class="title">二、使用须知</p>
				<p>2.1 隐私条款</p>
				<p>声明：</p>
				<p>依照法律规定删除违法信息是剑盟的法定义务，当事方不需要委托第三方进行投诉，剑盟亦未与任何中介机构合作开展此项业务。</p>
				<p>2.1.1 剑盟不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在剑盟的非公开内容，但下列情况除外：</p>
				<p class="indent">2.1.1.1 事先获得用户的明确授权；</p>
				<p class="indent">2.1.1.2 根据有关的法律法规要求；</p>
				<p class="indent">2.1.1.3 按照相关政府部门的要求；</p>
				<p class="indent">2.1.1.4 为维护社会公众的利益。</p>
				<p>2.1.2 剑盟可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与剑盟同等的保护用户隐私的责任，则剑盟有权将用户的注册资料等提供给该第三方。</p>
				<p>2.1.3 在不透露单个用户隐私资料的前提下，剑盟有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
				<p>2.2 版权声明</p>
				<p>2.2.1 用户发表并将其上传到剑盟的任何内容，剑盟在全世界范围内不限形式和载体地享有永久的、不可撤销的、免费的、非独家的使用权和转授权的权利，包括但不限于修改、复制、发行、展览、改编、汇编、出版、翻译、信息网络传播、广播、表演和再创作及著作权法等法律法规确定的其他权利。</p>
				<p>2.2.2 剑盟特有的标识、版面设计、编排方式等版权均属剑盟享有，未经剑盟许可，不得任意复制或转载。</p>
				<p>2.2.3 用户从剑盟的服务中获得的信息，未经剑盟许可，不得任意复制或转载。</p>
				<p>2.2.4 使用剑盟的任何内容均应注明"来源于剑盟"及署上作者姓名，按法律规定需要支付稿酬的，应当通知剑盟及作者及支付稿酬，并独立承担一切法律责任。</p>
				<p>2.2.5 剑盟所有内容仅代表作者自己的立场和观点，与剑盟无关，由作者本人承担一切法律责任。</p>
				<p>2.2.6 恶意转载剑盟内容的，剑盟保留将其诉诸法律的权利。</p>
			</div>
			<div class="item">
				<p class="title">三、奖励机制</p>
				<p>3.1 积分/金币用途</p>
				<p>3.1.1 积分说明</p>
				<p>3.1.1.1 积分来源：用户通过注册剑盟、资料学习下载、发帖点赞评论等互动行为可获取相应积分和金币（参考金币获取途径）</p>
				<p>3.1.1.2 根据用户积分对应的区间，获得不同的等级称号</p>
				<p>3.1.2 等级说明</p>
				<p>3.1.2.1 根据相应的积分区间，对应用户不同的等级，具体如下：</p>
				<p class="indent">3.1.2.1.1 1-200 积分，用户等级为“剑门子弟”</p>
				<p class="indent">3.1.2.1.2 201-1000 积分，用户等级为“剑客新秀”</p>
				<p class="indent">3.1.2.1.3 1001-5000 积分，用户等级为“剑法高手”</p>
				<p class="indent">3.1.2.1.4 5001-10000 积分，用户等级为“剑道精英”</p>
				<p class="indent">3.1.2.1.5 10000 积分以上，用户等级为“剑派宗师”</p>
				<p>3.1.2.2 用户等级不同，享有的权益不同。一般来说，等级越高，权益越多，具体依据微站实际公示为准。</p>
				<p>3.1.2.3 参与活动：部分福利活动会限制积分等级高于规定才可参与。</p>
				<p>3.1.3 金币说明</p>
				<p>3.2 积分/金币获取途径</p>
				<table>
					<tbody>
						<tr>
							<td width="45%">细则</td>
							<td width="10%">金币/积分</td>
							<td width="45%">备注</td>
						</tr>
						<tr>
							<td>个人信息</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>注册</td>
							<td>20</td>
							<td>通过手机号/邮箱完成</td>
						</tr>
						<tr>
							<td>完善资料</td>
							<td>10</td>
							<td></td>
						</tr>
						<tr>
							<td>绑定邮箱/手机/微信</td>
							<td>30</td>
							<td></td>
						</tr>
						<tr>
							<td>每日签到</td>
							<td>2</td>
							<td></td>
						</tr>
						<tr>
							<td>连续7日签到</td>
							<td>10</td>
							<td>积分加成，每日签到积分仍可获得</td>
						</tr>
						<tr>
							<td>剑盟码邀请注册</td>
							<td>100</td>
							<td>
								填写邀请码成功注册后，邀请者与注册者均获得100金币的奖励。
							</td>
						</tr>
						<tr>
							<td>习剑</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>学习资料（观看视频超过5分钟）</td>
							<td>2</td>
							<td>每日上限20</td>
						</tr>
						<tr>
							<td>学习资料（下载资料）</td>
							<td>2</td>
							<td>每日上限20</td>
						</tr>
						<tr>
							<td>分享学习资料</td>
							<td>5</td>
							<td>每日上限20</td>
						</tr>
						<tr>
							<td>驭剑</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>发表帖子</td>
							<td>10</td>
							<td>每日上限50</td>
						</tr>
						<tr>
							<td>点赞帖子</td>
							<td>1</td>
							<td>每日上限5</td>
						</tr>
						<tr>
							<td>收藏帖子</td>
							<td>2</td>
							<td>每日上限10</td>
						</tr>
						<tr>
							<td>评论帖子</td>
							<td>2</td>
							<td>每日上限10</td>
						</tr>
						<tr>
							<td>帖子被回复/评论</td>
							<td>2</td>
							<td>每日上限10</td>
						</tr>
						<tr>
							<td>帖子被收藏</td>
							<td>5</td>
							<td>每日上限20</td>
						</tr>
						<tr>
							<td>帖子被打赏（金币）</td>
							<td>5/10/20</td>
							<td>打赏者打赏的金币转入被打赏者账户，打赏后：总积分需>=50且积分需>0；总积分>=50才可被打赏</td>
						</tr>
						<tr>
							<td>帖子进入热榜的TOP 前10</td>
							<td>50</td>
							<td>进入剑盟论坛热榜TOP前10的用户，每个ID可获得50积分。每周进行一次统计，次周周一24点前下发奖励积分</td>
						</tr>
					</tbody>
				</table>
				<p>3.3 积分扣除声明</p>
				<p>用户如有以下情况或其他违法违规行为（详见投诉须知），将会被管理员评判为扣分行为，并扣除相应的用户积分。</p>
				<p class="indent">3.3.1 无实质性内容，大面积的重复复制粘贴相同的内容，重复或多次的刷屏发帖，视为注水帖，管理员视情况严重程度扣除10-50积分；</p>
				<p class="indent">3.3.2 帖子遭到举报（详见投诉须知）并被管理员判定为“违规内容”，管理员视情况严重程度扣除10-50积分；</p>
				<p class="indent">3.3.3 多次重复发送以营利为目的的广告性质内容，管理员视情况严重程度扣除10-20积分。</p>
			</div>
			<div class="item">
				<p class="title">四、用户行为规范</p>
				<p>4.1 用户行为规范</p>
				<p>除非法律允许或者经剑盟事先书面许可，您使用剑盟相关平台或服务不得具有下列行为：</p>
				<p class="indent">4.1.1 利用或针对剑盟相关软件或服务进行任何危害计算机网络安全的行为，包括但不限于：</p>
				<p class="indent">4.1.2 使用未经许可的数据或进入未经许可的服务器/帐户；</p>
				<p class="indent">4.1.3 未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</p>
				<p class="indent">4.1.4 未经许可，企图探查、扫描、测试剑盟相关软件或服务的系统或网络的弱点或实施其他破坏网络安全的行为；</p>
				<p class="indent">4.1.5 企图干涉、破坏剑盟相关软件或服务的系统或剑盟的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</p>
				<p class="indent">4.1.6 非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；</p>
				<p class="indent">4.1.7 提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</p>
				<p class="indent">4.1.8 明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；</p>
				<p class="indent">4.1.9 伪造TCP/ IP数据包名称或部分名称；</p>
				<p class="indent">4.1.10 恶意注册、使用剑盟帐号，包括但不限于频繁、批量注册帐号、建立虚假账号、故意冒充他人等。</p>
				<p class="indent">4.1.11 提交、发布虚假信息，或盗用他人头像或资料，冒充任何人或机构，或以任何方式方式谎称或误导，使人误认为与任何人或任何机构有关。</p>
				<p class="indent">4.1.12 强制、诱导其他用户关注、点击链接页面或分享信息的;</p>
				<p class="indent">4.1.13 虚构事实、隐瞒真相以误导、欺骗他人的；</p>
				<p class="indent">4.1.14 利用剑盟的账户或本协议项下的服务从事任何违法犯罪活动的</p>
				<p class="indent">4.1.15 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</p>
				<p class="indent">4.1.16 剑盟有权对用户使用剑盟相关平台或服务的情况进行审查和监督，如剑盟有理由认为用户在使用剑盟相关软件或服务时违反上述任何规定，剑盟或其授权的人或机构在任何时候有权进行独立判断并采取技术手段予以删除、屏蔽、断开链接，无需任何通知，同时，剑盟有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止剑盟账号使用，追究相关法律责任等措施。</p>
				<p class="indent">4.1.17 剑盟有权对用户使用剑盟相关平台或服务的情况进行审查和监督，如剑盟有理由认为用户在使用剑盟相关软件或服务时违反上述任何规定，剑盟或其授权的人或机构在任何时候有权进行独立判断并采取技术手段予以删除、屏蔽、断开链接，无需任何通知，同时，剑盟有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止剑盟账号使用，追究相关法律责任等措施。</p>
				<p class="indent">4.1.18 用户不得利用剑盟服务制作、上载、复制、发布、传播或者转载如下内容：</p>
				<p class="indent">4.1.18.1 反对宪法所确定的基本原则的；</p>
				<p class="indent">4.1.18.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
				<p class="indent">4.1.18.3 损害国家荣誉和利益的；</p>
				<p class="indent">4.1.18.4 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
				<p class="indent">4.1.18.5 侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；</p>
				<p class="indent">4.1.18.6 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
				<p class="indent">4.1.18.7 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
				<p class="indent">4.1.18.8 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
				<p class="indent">4.1.18.9 侮辱或者诽谤他人，侵害他人合法权益的；</p>
				<p class="indent">4.1.18.10 含有法律、行政法规禁止的其他内容的信息。</p>
				<p>4.2 投诉须知</p>
				<p>尊重合法版权，反对侵权盗版是剑盟的宗旨之一，为维护合法权利人的合法权益，剑盟依照法律法规受理侵犯企业或个人合法权益的投诉行为，合法权利人发现权益受到侵害时，可按本须知进行投诉。</p>
				<p>当您发现剑盟上存在涉嫌侵犯您个人合法权益或其他合法权益的内容时，您可以通过“举报”按钮，向剑盟提出诉求。</p>
				<p>剑盟可能采取删除违规内容、断开违规内容链接、暂停或终止违规用户帐号功能等方式，对以下违法、不良信息或存在危害的行为进行处理。</p>
				<p>4.2.1 违反法律法规的信息,主要表现为：</p>
				<p class="indent">4.2.1.1 反对宪法所确定的基本原则</p>
				<p class="indent">4.2.1.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一，损害国家荣誉和利益</p>
				<p class="indent">4.2.1.3 侮辱、滥用英烈形象，歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉</p>
				<p class="indent">4.2.1.4 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动</p>
				<p class="indent">4.2.1.5 煽动民族仇恨、民族歧视，破坏民族团结</p>
				<p class="indent">4.2.1.6 破坏国家宗教政策，宣扬邪教和封建迷信</p>
				<p class="indent">4.2.1.7 散布谣言，扰乱社会秩序，破坏社会稳定</p>
				<p class="indent">4.2.1.8 宣扬淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪</p>
				<p class="indent">4.2.1.9 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序</p>
				<p class="indent">4.2.1.10 侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益</p>
				<p class="indent">4.2.1.11 含有法律、行政法规禁止的其他内容</p>
				<p class="indent">4.2.2 不友善：不尊重剑盟用户及其所贡献内容的信息或行为。主要表现为：</p>
				<p class="indent">4.2.2.1 轻蔑：贬低、轻视他人及其劳动成果</p>
				<p class="indent">4.2.2.2 诽谤：捏造、散布虚假事实，损害他人名誉</p>
				<p class="indent">4.2.2.3 嘲讽：以比喻、夸张、侮辱性的手法对他人或其行为进行揭露或描述，以此来激怒他人</p>
				<p class="indent">4.2.2.4 挑衅：以不友好的方式激怒他人，意图使对方对自己的言论作出回应，蓄意制造事端</p>
				<p class="indent">4.2.2.5 羞辱：贬低他人的能力、行为、生理或身份特征，让对方难堪</p>
				<p class="indent">4.2.2.6 谩骂：以不文明的语言对他人进行负面评价</p>
				<p class="indent">4.2.2.7 歧视：煽动人群歧视、地域歧视等，针对他人的民族、种族、宗教、性取向、性别、年龄、地域、生理特征等身份或者归类的攻击</p>
				<p class="indent">4.2.2.8 威胁：许诺以不良的后果来迫使他人服从自己的意志</p>
				<p>4.2.3 发布垃圾广告信息：以推广曝光为目的，发布影响用户体验、扰乱剑盟秩序的内容，或进行相关行为。主要表现为：</p>
				<p class="indent">4.2.3.1 多次发布包含售卖产品、提供服务、宣传推广内容的垃圾广告。包括但不限于以下几种形式：</p>
				<p class="indent">4.2.3.1.1 单个帐号多次发布包含垃圾广告的内容</p>
				<p class="indent">4.2.3.1.2 多个广告帐号互相配合发布、传播包含垃圾广告的内容</p>
				<p class="indent">4.2.3.1.3 多次发布包含欺骗性外链的内容，如未注明的淘宝客链接、跳转网站等，诱骗用户点击链接</p>
				<p class="indent">4.2.3.1.4 发布大量包含 SEO 推广链接、产品、品牌等内容获取搜索引擎中的不正当曝光</p>
				<p class="indent">4.2.3.2 购买或出售帐号之间虚假地互动，发布干扰剑盟秩序的推广内容及相关交易。包括但不限于以下几种形式：</p>
				<p class="indent">4.2.3.2.1 购买机器注册帐号，或人工操控帐号的关注，伪造在剑盟内的影响力</p>
				<p class="indent">4.2.3.2.2 购买机器注册帐号，或人工操控帐号点击赞同，谋求回答的不正当曝光</p>
				<p class="indent">4.2.3.2.3 使用机器、恶意程序手段或人为有组织性地操控帐号进行点赞、回答等扰乱秩序的行为</p>
				<p class="indent">4.2.3.3 使用严重影响用户体验的违规手段进行恶意营销。包括但不限于以下几种形式：</p>
				<p class="indent">4.2.3.3.1 不规范转载或大篇幅转载他人内容同时加入推广营销内容</p>
				<p class="indent">4.2.3.3.2 发布包含欺骗性的恶意营销内容，如通过伪造经历、冒充他人等方式进行恶意营销</p>
				<p class="indent">4.2.3.3.3 使用特殊符号、图片等方式规避垃圾广告内容审核的广告内容。</p>
				<p>4.2.4 恶意行为：滥用产品功能，进行影响用户体验、危及平台安全及损害他人权益的行为。主要表现为：</p>
				<p class="indent">4.2.4.1 恶意编辑，指清空或删除有效内容，添加无关信息，破坏内容结构等降低公共编辑内容质量的编辑</p>
				<p class="indent">4.2.4.2 干扰正常用户体验的内容。包括但不限于以下几种形式：</p>
				<p class="indent">4.2.4.2.1 在不同问题下重复发布相同或近似的回答内容</p>
				<p class="indent">4.2.4.2.2 频繁发布难以辨识涵义影响阅读体验的字符、数字等无意义乱码</p>
				<p class="indent">4.2.4.2.3 骚扰他人，以评论、私信等方式对他人反复发送重复或者相似的诉求</p>
				<p class="indent">4.2.4.3 诱导投票或关注，如通过点赞抽奖、集赞更新等形式诱导投票或者诱导关注</p>
				<p class="indent">4.2.4.3.1 制作及传播外挂或者用于操作帐号功能的恶意程序或相关教程</p>
				<p class="indent">4.2.4.3.2 发布含有潜在危险的内容，如钓鱼网站、木马、病毒网站等</p>
				<p class="indent">4.2.4.4 恶意注册使用剑盟帐号，包括但不限于机器注册、频繁注册、批量注册、滥用多个剑盟帐号</p>
				<p>4.2.5 色情低俗信息，主要表现为：</p>
				<p class="indent">4.2.5.1 包含自己或他人性经验的细节描述或露骨的感受描述</p>
				<p class="indent">4.2.5.2 涉及色情段子、两性笑话的低俗内容</p>
				<p class="indent">4.2.5.3 配图、头图中包含庸俗或挑逗性图片的内容</p>
				<p class="indent">4.2.5.4 带有性暗示、性挑逗等易使人产生性联想</p>
				<p class="indent">4.2.5.5 展现血腥、惊悚、残忍等致人身心不适</p>
				<p class="indent">4.2.5.6 炒作绯闻、丑闻、劣迹等</p>
				<p class="indent">4.2.5.7 宣扬低俗、庸俗、媚俗内容影响的其他内容。</p>
				<p>4.2.6 不实信息，主要表现为：</p>
				<p class="indent">4.2.6.1 可能存在事实性错误或者造谣等内容</p>
				<p class="indent">4.2.6.2 存在事实夸大、伪造虚假经历等误导他人的内容</p>
				<p class="indent">4.2.6.3 伪造身份、冒充他人，通过头像、用户名等个人信息暗示自己具有特定身份，或与特定机构或个人存在关联</p>
				<p>4.2.7 传播封建迷信，主要表现为：</p>
				<p class="indent">4.2.7.1 找人算命、测字、占卜、解梦、化解厄运、使用迷信方式治病：</p>
				<p class="indent">4.2.7.1.1 求推荐算命看相大师</p>
				<p class="indent">4.2.7.1.2 针对具体风水等问题进行求助或咨询</p>
				<p class="indent">4.2.7.1.3 问自己或他人的八字、六爻、星盘、手相、面相、五行缺失，包括通过占卜方法问婚姻、前程、运势，东西宠物丢了能不能找回、取名改名等</p>
				<p class="indent">4.2.7.1.4 解梦类内容</p>
				<p class="indent">4.2.7.2 宣扬鼓励封建迷信等活动，推广算命等理论知识学习及讨论：</p>
				<p class="indent">4.2.7.2.1 询问某种算命结果、说法是否准确</p>
				<p class="indent">4.2.7.2.2 在回答或文章中推荐关注算命、占卜等封建迷信活动</p>
				<p>4.2.8 文章标题党，主要表现为：</p>
				<p class="indent">4.2.8.1 以各种夸张、猎奇、不合常理的表现手法等行为来诱导用户</p>
				<p class="indent">4.2.8.2 内容与标题之间存在严重不实或者原意扭曲</p>
				<p class="indent">4.2.8.3 使用夸张标题，内容与标题严重不符的</p>
				<p>4.2.9 其他危害行为或内容，主要表现为：</p>
				<p class="indent">4.2.9.1 可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等</p>
				<p class="indent">4.2.9.2 不当评述自然灾害、重大事故等灾难的</p>
				<p class="indent">4.2.9.3 美化、粉饰侵略战争行为的</p>
				<p class="indent">4.2.9.4 法律、行政法规禁止，或可能对网络生态造成不良影响的其他内容。</p>
				<p>4.3 违规行为处罚</p>
				<p>当用户发布违规内容时，剑盟方将依据相关用户违规情节严重程度，对帐号进行禁言 1 天、7 天、15 天直至永久禁言的处罚。对于使用剑盟时间较长，并取得一定剑盟成就的用户，剑盟将视违规情节严重程度，酌情从轻或减轻处罚。当通过作弊手段注册、使用帐号，或者滥用多个帐号发布违规内容时，剑盟将加重处罚。</p>
				<p class="indent">4.3.1 合法权利人如还有其它疑问，可直接在线客服平台咨询。</p>
				<p class="indent">4.3.2 剑维客服收到完整的投诉材料后，会立即移交相关部门进行处理。提交的投诉材料不完整的，视为投诉未成功，剑维客服不予处理，投诉人有疑问或异议的，请致电剑维客服24小时热线电话。</p>
				<p class="indent">4.3.3 合法权利人应对其提供材料的真实性负责并承担由此造成的全部法律责任。</p>
				<p class="indent">4.3.4 为使您的投诉顺利，避免您的损失，请务必按本须知的规定谨慎投诉。</p>
			</div>
			<div class="item">
				<p class="title">免责声明</p>
				<p>规则由剑盟制定并依据国家相关法律法规及规章制度予以解释和修改，规则以剑盟公布为准，更新后将另行通知。</p>
				<p>本规则解释权属于剑盟，未尽事宜将逐渐补充。</p>
			</div>
		</div>
	</div>
</template>

<script>
	import { dataState } from "@/api/all.js";
  import { GetWxShares } from "@/utils/common";

	export default {
		name: "AgreeMent",
		data() {
			return {

			};
		},
		mounted() {
    const share = {
      title: 'AVEVA 社区微站用户使用协议（剑盟协议）',
      desc: ''
    }
    GetWxShares(share);
			// 数据统计
			const data ={
				"visitType": 1,
				"gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
				"url": window.location.href,
				"pageUrl": "FenceIndex",
				"relationId": ''
			}
			dataState(data).then(res => {
				sessionStorage.setItem('gid',res.data);
			});
		},
		methods: {

		},
	};
</script>

<style lang="scss" scoped>
	$mobil_base: 750;

	@function vw($true) {
		@return ($true / $mobil_base) * 100vw;
	}

	.content{
		padding: 10px 5% 20px 5%;
		line-height: 1.5;
		color: #686868;
		.item{
			.title{
				color: red;
				font-size: 100%;
				padding: 10px 0 6px 0;
			}
			.indent{
				padding-left: 40px;
			}
			table{
				width: 100%;
				border-collapse: collapse;
				border: 1px solid #aaa;
				tr{
					td{
						vertical-align: baseline;
						padding: 10px;
						border: 1px solid #3F3F3F;
						text-align: left;
						color: #686868;
					}
					&:first-of-type{
						background-color: #6b18a8;
						td{
							color: #fff;
						}
					}
				}
				td{
					padding: 10px;
				}
			}
		}
	}

	@media (max-width: 750px) {}
</style>
